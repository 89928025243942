.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
          animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #eee, -1px 0 0 #eee, 0 1px 0 #eee, 0 -1px 0 #eee, 0 3px 13px rgba(0,0,0,0.08);
          box-shadow: 1px 0 0 #eee, -1px 0 0 #eee, 0 1px 0 #eee, 0 -1px 0 #eee, 0 3px 13px rgba(0,0,0,0.08);
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
          animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
          box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #eee;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #eee;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #eee;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: #3c3f40;
  fill: #3c3f40;
  height: 28px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  -webkit-flex: 1;
  -ms-flex: 1;
      flex: 1 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 16px;
  height: 28px;
  padding: 10px;
  z-index: 3;
  color: #3c3f40;
  fill: #3c3f40;
}
.flatpickr-months .flatpickr-prev-month.disabled,
.flatpickr-months .flatpickr-next-month.disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  left: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  right: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #f64747;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(64,72,72,0.15);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0,0,0,0.1);
}
.numInputWrapper span:active {
  background: rgba(0,0,0,0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(64,72,72,0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(64,72,72,0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(60,63,64,0.5);
}
.numInputWrapper:hover {
  background: rgba(0,0,0,0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6.16px 0 0 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0,0,0,0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #3c3f40;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #3c3f40;
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(60,63,64,0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
      align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
      flex: 1 1;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0,0,0,0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-flex: 1;
  -ms-flex: 1;
      flex: 1 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
      align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #eee;
          box-shadow: -1px 0 0 #eee;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #404848;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
      -webkit-flex-basis: 14.2857143%;
          flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e9e9e9;
  border-color: #e9e9e9;
}
.flatpickr-day.today {
  border-color: #f64747;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #f64747;
  background: #f64747;
  color: #fff;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #4f99ff;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  border-color: #4f99ff;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #4f99ff;
          box-shadow: -10px 0 0 #4f99ff;
}
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e9e9e9, 5px 0 0 #e9e9e9;
          box-shadow: -5px 0 0 #e9e9e9, 5px 0 0 #e9e9e9;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(64,72,72,0.3);
  background: transparent;
  border-color: #e9e9e9;
  cursor: default;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: rgba(64,72,72,0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #4f99ff, 5px 0 0 #4f99ff;
          box-shadow: -5px 0 0 #4f99ff, 5px 0 0 #4f99ff;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #eee;
          box-shadow: 1px 0 0 #eee;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(64,72,72,0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  -webkit-flex: 1;
  -ms-flex: 1;
      flex: 1 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #404848;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #404848;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #404848;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #404848;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #f1f1f1;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.flatpickr-calendar {
  width: 307.875px;
}
.dayContainer {
  padding: 0;
  border-right: 0;
}
span.flatpickr-day,
span.flatpickr-day.prevMonthDay,
span.flatpickr-day.nextMonthDay {
  border-radius: 0 !important;
  border: 1px solid #e9e9e9;
  max-width: none;
  border-right-color: transparent;
}
span.flatpickr-day:nth-child(n+8),
span.flatpickr-day.prevMonthDay:nth-child(n+8),
span.flatpickr-day.nextMonthDay:nth-child(n+8) {
  border-top-color: transparent;
}
span.flatpickr-day:nth-child(7n-6),
span.flatpickr-day.prevMonthDay:nth-child(7n-6),
span.flatpickr-day.nextMonthDay:nth-child(7n-6) {
  border-left: 0;
}
span.flatpickr-day:nth-child(n+36),
span.flatpickr-day.prevMonthDay:nth-child(n+36),
span.flatpickr-day.nextMonthDay:nth-child(n+36) {
  border-bottom: 0;
}
span.flatpickr-day:nth-child(-n+7),
span.flatpickr-day.prevMonthDay:nth-child(-n+7),
span.flatpickr-day.nextMonthDay:nth-child(-n+7) {
  margin-top: 0;
}
span.flatpickr-day.today:not(.selected),
span.flatpickr-day.prevMonthDay.today:not(.selected),
span.flatpickr-day.nextMonthDay.today:not(.selected) {
  border-color: #e9e9e9;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #f64747;
}
span.flatpickr-day.today:not(.selected):hover,
span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
  border: 1px solid #f64747;
}
span.flatpickr-day.startRange,
span.flatpickr-day.prevMonthDay.startRange,
span.flatpickr-day.nextMonthDay.startRange,
span.flatpickr-day.endRange,
span.flatpickr-day.prevMonthDay.endRange,
span.flatpickr-day.nextMonthDay.endRange {
  border-color: #4f99ff;
}
span.flatpickr-day.today,
span.flatpickr-day.prevMonthDay.today,
span.flatpickr-day.nextMonthDay.today,
span.flatpickr-day.selected,
span.flatpickr-day.prevMonthDay.selected,
span.flatpickr-day.nextMonthDay.selected {
  z-index: 2;
}
.rangeMode .flatpickr-day {
  margin-top: -1px;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.flatpickr-weekwrapper span.flatpickr-day {
  border: 0;
  margin: -1px 0 0 -1px;
}
.hasWeeks .flatpickr-days {
  border-right: 0;
}

.os-tabs-container {
  /* box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); */
  height: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}
.os-tabs-inner {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  padding: 0px 20px;
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  margin: 0 -20px;
  box-sizing: border-box;
}
.os-tabs-inner-small {
  box-sizing: border-box;
  margin: 0;
}
.os-tabs-icon {
  position: absolute;
  height: 45px;
  width: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #949494;
  transition: all 0.2s ease;
}
.os-tabs-shadow {
  float: left;
  height: 45px;
  width: 30px;
  position: absolute;
}
.os-tabs-item {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 30px;
}
.os-tabs-item a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  color: #949494;
  font-size: 18px;
  border-bottom: transparent 1px solid;
  box-sizing: border-box;
}
.os-tabs-item:last-child::after {
  width: 20px;
  content: '';
}
.os-tabs-item:not(.os-tabs-itemActive) a:hover {
  border-bottom: 1px solid #e1e1e1;
}
.os-tabs-itemActive a {
  color: #0069ff;
  border-bottom: 1px solid #0069ff;
}
.os_navbar {
  position: fixed;
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
@media print {
  .os_navbar {
    display: none;
  }
}
.os_navbar_margin {
  height: 80px;
}
@media print {
  .os_navbar_margin {
    display: none;
  }
}
.os_navbar-logo {
  height: 44px;
}
.os_bounce_loading {
  text-align: center;
}
.os_bounce_loading > div {
  border-radius: 100%;
  display: inline-block;
  margin: 0px 5px;
  -webkit-animation-name: bouncedelay;
          animation-name: bouncedelay;
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.os_bounce_loading .os_bounce_bounce2 {
  -webkit-animation-delay: 0.16s;
          animation-delay: 0.16s;
}
.os_bounce_loading .os_bounce_bounce3 {
  -webkit-animation-delay: 0.32s;
          animation-delay: 0.32s;
}
@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/* Copyright 2013-2015 etc Chris Tabor. See https://github.com/christabor/css-progress-wizard/blob/master/LICENSE for details. */
.os_wizard_container {
  position: relative;
  z-index: 10;
  margin-top: 20px;
}
.os_wizard_content {
  margin-top: 50px;
}
.progress-indicator {
  display: -webkit-flex;
  display: flex;
  margin: 0;
  padding: 0;
  margin-bottom: 1em;
}
.progress-indicator > li {
  -webkit-flex: 1 1;
          flex: 1 1;
  list-style: none;
  text-align: center;
  width: auto;
  padding: 0;
  margin: 0;
  position: relative;
  text-overflow: ellipsis;
  color: #aaa;
  display: block;
}
.progress-indicator > li .bubble {
  z-index: 2;
  border-radius: 1000px;
  width: 15px;
  height: 15px;
  background-color: #aaa;
  display: block;
  margin: 0 auto 0.5em auto;
}
.progress-indicator > li .bubble:before,
.progress-indicator > li .bubble:after {
  display: block;
  position: absolute;
  z-index: -1;
  top: 4.5px;
  width: 100%;
  height: 5px;
  content: '';
  background-color: #eee;
}
.progress-indicator > li .bubble:before {
  left: 0;
}
.progress-indicator > li .bubble:after {
  right: 50%;
}
.progress-indicator > li:first-child .bubble:before,
.progress-indicator > li:first-child .bubble:after {
  width: 0%;
  margin-left: 50%;
}
.progress-indicator > li:last-child .bubble:before,
.progress-indicator > li:last-child .bubble:after {
  width: 50%;
  margin-right: 50%;
}
.progress-indicator > li.completed {
  color: #0069ff;
}
.progress-indicator > li.completed .bubble {
  background-color: #0069ff;
  color: #0069ff;
}
.progress-indicator > li.completed .bubble:before,
.progress-indicator > li.completed .bubble:after {
  background-color: #0069ff;
}
.progress-indicator > li.active {
  color: #0069ff;
}
.progress-indicator > li.active .bubble {
  background-color: #0069ff;
  color: #0069ff;
}
.progress-indicator > li.active .bubble:before,
.progress-indicator > li.active .bubble:after {
  background-color: #0069ff;
}
.progress-indicator > li a:hover .bubble {
  background-color: #5671d0;
  color: #5671d0;
}
.progress-indicator > li a:hover .bubble:before,
.progress-indicator > li a:hover .bubble:after {
  background-color: #5671d0;
}
.progress-indicator > li.danger .bubble {
  background-color: #d3140f;
  color: #d3140f;
}
.progress-indicator > li.danger .bubble:before,
.progress-indicator > li.danger .bubble:after {
  background-color: #d3140f;
}
.progress-indicator > li.warning .bubble {
  background-color: #edb10a;
  color: #edb10a;
}
.progress-indicator > li.warning .bubble:before,
.progress-indicator > li.warning .bubble:after {
  background-color: #edb10a;
}
.progress-indicator > li.info .bubble {
  background-color: #5b32d6;
  color: #5b32d6;
}
.progress-indicator > li.info .bubble:before,
.progress-indicator > li.info .bubble:after {
  background-color: #5b32d6;
}
.progress-indicator.stacked {
  display: block;
}
.progress-indicator.stacked > li {
  text-indent: -10px;
  text-align: center;
  display: block;
}
.progress-indicator.stacked > li .bubble:before,
.progress-indicator.stacked > li .bubble:after {
  left: 50%;
  margin-left: -2.5px;
  width: 5px;
  height: 100%;
}
.progress-indicator.stacked .stacked-text {
  position: relative;
  z-index: 10;
  top: 0;
  margin-left: 60% !important;
  width: 45% !important;
  display: inline-block;
  text-align: left;
  line-height: 1.2em;
}
.progress-indicator.stacked > li a {
  border: none;
}
.progress-indicator.stacked.nocenter > li .bubble {
  margin-left: 0;
  margin-right: 0;
}
.progress-indicator.stacked.nocenter > li .bubble:before,
.progress-indicator.stacked.nocenter > li .bubble:after {
  left: 7.5px;
}
.progress-indicator.stacked.nocenter .stacked-text {
  width: auto !important;
  display: block;
  margin-left: 30px !important;
}
@media handheld, screen and (max-width: 400px) {
  .progress-indicator {
    font-size: 80%;
  }
}
.os_modal_content {
  outline: none;
  background-color: #fff;
  padding: 20px;
}
.os_modal_content .os_title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}
.os_modal_content .os_message {
  margin-bottom: 20px;
}
.os_modal_content .os_buttons {
  text-align: right;
}
.os_modal_content .os_cancelButton {
  margin-right: 10px;
}
@media only screen and (max-width: 480px) {
  .os_modal_content .os_title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }
  .os_modal_content .os_buttons {
    text-align: center;
  }
}
.os_container {
  margin: 0 auto;
  padding: 0 calc(20px + env(safe-area-inset-left));
}
.os_container_big {
  max-width: 1200px;
}
.os_container_medium {
  max-width: 900px;
}
.os_container_small {
  max-width: 600px;
}
.os_buttonLink {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.os_buttonLink:hover {
  text-decoration: none;
}
.orion_button {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  outline: none;
  min-width: 15px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: black;
  text-decoration: none;
  transition: all 0.15s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.os_button_container {
  margin-right: 10px;
}
.os_button_container:last-child {
  margin-right: auto;
}
.orion_button * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.orion_fullWidth {
  display: block;
}
.orion_button:hover {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.orion_button:active {
  background-color: #f6f9fc;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.orion_disabled {
  color: #9e9e9e;
  background: #e0e0e0;
  box-shadow: none;
}
.orion_disabled:hover {
  color: #9e9e9e;
  background: #e0e0e0;
  -webkit-transform: none;
          transform: none;
  box-shadow: none;
  cursor: default;
}
.orion_loading {
  color: #9e9e9e;
  background: #e0e0e0;
  box-shadow: none;
}
.orion_loading:hover {
  color: #9e9e9e;
  background: #e0e0e0;
  -webkit-transform: none;
          transform: none;
  box-shadow: none;
  cursor: default;
}
.orion_disabled:active {
  background: #e0e0e0;
}
.orion_primary {
  color: white;
  background: #2196f3;
}
.orion_primary:active {
  background-color: #1e88e5;
}
.orion_danger {
  color: white;
  background: #f44336;
}
.orion_danger:active {
  background-color: #e53935;
}
.orion_big {
  height: 45px;
  line-height: 45px;
  padding: 0 14px;
  font-size: 18px;
  letter-spacing: 0.04em;
}
.os_dropover {
  z-index: 1;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  min-width: 220px;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  right: 0;
  top: 46px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}
.os_dropover-enter,
.os_dropover-leave.os_dropover-leave-active {
  opacity: 0;
  -webkit-transform: rotateX(-15deg);
          transform: rotateX(-15deg);
  -webkit-transform-origin: 50% -50px;
          transform-origin: 50% -50px;
}
.os_dropover-enter.os_dropover-enter-active,
.os_dropover-leave {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  transition: all 300ms ease;
}
.os-navigator-container {
  /* box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); */
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}
.os-navigator-inner {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  padding: 0px 20px;
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  margin: 0 -20px;
  box-sizing: content-box;
}
.os-navigator-inner-small {
  box-sizing: border-box;
  margin: 0;
}
.os-navigator-icon {
  position: absolute;
  height: 60px;
  width: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  transition: all 0.2s ease;
}
.os-navigator-shadow {
  float: left;
  height: 60px;
  width: 30px;
  position: absolute;
}
.os-navigator-item {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 30px;
}
.os-navigator-item a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  color: #fff;
  font-family: 'Geomanist', 'Open Sans', sans-serif;
  font-size: 20px;
  border-bottom: transparent 2px solid;
  box-sizing: border-box;
}
.os-navigator-item:last-child::after {
  width: 20px;
  content: '';
}
.os-navigator-itemActive a {
  border-bottom: #fff 2px solid;
}
.os_autocomplete_item {
  padding: 8px 10px;
  font-size: 16px;
  color: #373737;
  cursor: pointer;
}
.os_autocomplete_item.highlighted {
  background-color: #f4f4f4;
}
.os-checkbox-container label {
  height: 44px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}
.os-checkbox-label {
  margin-left: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.os_toggle_container {
  margin-top: 7px;
}
.os_toggle_label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: -9px;
  margin-left: 10px;
  color: #464646;
  font-size: 18px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.react-ios-switch-Switch-handle {
  border-radius: 13px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 4px 11px 0px rgba(0, 0, 0, 0.08), -1px 3px 3px 0px rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  cursor: -webkit-grab;
  cursor: grab;
  height: 26px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 26px;
}
.react-ios-switch-Switch-handle:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.react-ios-switch-Switch-input {
  display: none;
}
.react-ios-switch-Switch-offState {
  border-radius: 13px;
  height: 26px;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 46px;
}
.react-ios-switch-Switch-switch {
  border-radius: 15px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 50px;
}
.react-ios-switch-Switch-switch.react-ios-switch-Switch-switch--disabled {
  cursor: default;
  opacity: 0.5;
}
.react-ios-switch-Switch-switch.react-ios-switch-Switch-switch--disabled .react-ios-switch-Switch-handle {
  cursor: default;
}
.react-ios-switch-Switch-switch.react-ios-switch-Switch-switch--disabled .react-ios-switch-Switch-handle:active {
  cursor: default;
}
.os-s-array {
  background-color: #fff;
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 10px;
  display: -webkit-flex;
  display: flex;
  border-left: 1px solid #eee;
  cursor: -webkit-grab;
  cursor: grab;
}
.os-s-array-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}
.os-array-item-content {
  -webkit-flex: 1 1;
          flex: 1 1;
}
.os-array-item-remove {
  padding-top: 2px;
  padding-left: 10px;
}
.os-colorpicker-color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}
.os-colorpicker-swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}
.os-colorpicker-popover {
  position: absolute;
  z-index: 2;
  margin-top: 30px;
  margin-bottom: 15px;
}
.os-colorpicker-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.os-input-container {
  margin-bottom: 10px;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-family: inherit;
  border: none;
  border-radius: 4px;
}
.os-input-text {
  -webkit-flex: 1 1;
          flex: 1 1;
  box-sizing: border-box;
  font-size: 16px;
  font-family: inherit;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  transition: border 0.2s ease;
  box-shadow: 0 0 0 1px rgba(220, 220, 220, 0.2);
}
.os-input-text:focus {
  border: 1px solid #0069ff;
  box-shadow: 0 0 0 0.5px rgba(49, 49, 93, 0.03), 0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}
.os-input-error {
  color: #ff3030;
  margin: 10px 0;
}
.os-input-container {
  margin-bottom: 10px;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-family: inherit;
  border: none;
  border-radius: 4px;
}
.os-input-text {
  -webkit-flex: 1 1;
          flex: 1 1;
  box-sizing: border-box;
  font-size: 16px;
  font-family: inherit;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  transition: border 0.2s ease;
  box-shadow: 0 0 0 1px rgba(220, 220, 220, 0.2);
}
.os-input-text:focus {
  border: 1px solid #0069ff;
  box-shadow: 0 0 0 0.5px rgba(49, 49, 93, 0.03), 0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}
.os-input-error {
  color: #ff3030;
  margin: 10px 0;
}
.orion-select__control {
  min-height: 46px !important;
  margin-bottom: 10px !important;
}
.orion-select__placeholder {
  color: #aaa !important;
}
.orion-select__control--is-focused {
  border-color: #0069ff !important;
  box-shadow: 0 0 0 1px #0069ff !important;
}
.os_iconButton {
  color: #131313;
  cursor: pointer;
  position: relative;
}
.os_iconButton :hover {
  color: #2b2b2b;
}
.os_iconButton :active {
  color: #636363;
}
.os_iconButton_loading {
  position: relative;
  cursor: default;
  color: #989898;
}
.os_iconButton_disabled {
  position: relative;
  cursor: default;
  color: #989898;
}

